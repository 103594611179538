import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {ApplicantItem} from "./applicants/applicant-item";
import {AcceptModal} from "./modals/accept-modal";
import {DeclineModal} from "./modals/decline-modal";
import {ApplicantTab} from "./applicants/applicant-tab"
import Flash from "../lib/flash";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

const FetchApplicants = () => {
  const [sessions, setSessions] = useState([]);
  const [session_data, setSessionData] = useState({});
  const [showMoreText, setShowMoreText] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [isDeclineButtonDisabled, setIsDeclineButtonDisabled] = useState(false);
  const [time, setTime] = useState("");
  const [reason, setReason] = useState( "");
  const [inquiryId, setInquiryId] = useState("");
  
  const toggleClass = () => {
    setShowMoreText(!showMoreText);
  };

  let handleOpenAcceptModal = (id) => {
    let acceptModal = new Foundation.Reveal($(`#draft-confirm-modal${id}`));
    acceptModal.open();
  }

  let handleOpenDeclineModal = (id) => {
    let declineModal = new Foundation.Reveal($(`#draft-decline-modal${id}`));
    declineModal.open();
  }

  useEffect(() => {
    if (time === "") {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [time]);

  useEffect(() => {
    if (reason === "") {
      setIsDeclineButtonDisabled(true);
    } else {
      setIsDeclineButtonDisabled(false);
    }
  }, [reason]);

  let handleSubmit = (action, id) =>  {
    setSubmitting(true);
    setIsSubmitButtonDisabled(true);
    setInquiryId(id);
    setReason(reason);
    
    let url = action === "decline" ? `/smallbusiness/sessions/inquiries/${id}/reject` : `/smallbusiness/sessions/inquiries/${id}/confirm`
    
    // set form data
    let command = new FormData();
    command.append('time', time)
    command.append('commands_event[reason]', reason)
    
    let page_url = window.location.href
   
    fetch(url, {
      method: 'POST',
      body: command,
      headers: {
        "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      }
    })
      .then(response => response.clone().json())
      .then(result => {
        if (result) {
          Flash.addMessage(
            result.message
          );
        } else {
          Flash.addError(
            result.errors
          );
        }
        setSubmitting(false);
        window.location= page_url;
        window.location.reload()
      });
  };

  useEffect(() => {
    fetch('/smallbusiness/applicants',
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
      .then(response => {
        return response.clone().json()
      })
      .then(result => {
        setSessions(result)
      });
  }, []);
  
  useEffect(() => {
    let _data = {
      session_inquiries: []
    };
    if(sessions.length) {
      _data = sessions.filter((item) => item.session_inquiries);
      const _session = [
        {
          session_list: _data,
        }
      ]
      setSessionData(_session);
    }
  }, [sessions])
  
  return(
    <div>
      {session_data.length && session_data.map(({session_list}, key) => (
        <div key={key}>
          {session_list.length && session_list.map(({description, session_inquiries, session_url }, key) => (
            <div key={key}>
              <Accordion allowZeroExpanded preExpanded={['item0']}>
                <AccordionItem key={key} uuid={"item"+key} >
                  <div className="l-row full">
                    <h4 key={key} className="page-title expand" style={{fontSize: 20}} >
                      <AccordionItemHeading>
                        <AccordionItemButton>  
                          <ApplicantTab
                              index={key++}
                              description={description}
                              inquiries={session_inquiries}
                            />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </h4>
                  </div>
                {session_inquiries.length && session_inquiries.map(inquiries => (
                  <div key={key++}>
                    <AccordionItemPanel>
                      <section>
                        <ApplicantItem 
                          inquiries={inquiries}
                          handleOpenAcceptModal={handleOpenAcceptModal}
                          handleOpenDeclineModal={handleOpenDeclineModal}
                          applicantIndex={key}
                          showMoreText={showMoreText}
                          toggleClass={toggleClass}
                          view_more_url={session_url}
                          engagement_type ="session"
                          action="confirm"
                          id = {inquiries.id}
                        />
                      </section>
                    </AccordionItemPanel>
                    {inquiries && inquiries.time_slots ?
                        <AcceptModal
                          modal_id="draft-confirm-modal"
                          action = "submit"
                          reschedule_url={inquiries.reschedule_url}
                          inquiryId={inquiries.id}
                          header={inquiries.user.name.split(" ")[0]+'\'s Availability'}
                          content="Choose from the time slots below for when you're available for the interview"
                          timeslots={inquiries && inquiries.time_slots ? inquiries.time_slots : []}
                          time_slots_formatted={inquiries && inquiries.time_slots_formatted ? inquiries.time_slots_formatted : []}
                          handleSubmit = {handleSubmit}
                          setTime = {setTime}
                          submitting = {submitting}
                          isSubmitButtonDisabled = {isSubmitButtonDisabled}
                      />
                    : ""}
                      <DeclineModal
                        header = "Confirm Decline"
                        content = "Please note that this will be sent directly to "
                        name = {inquiries.user.name}
                        inquiryId= {inquiries.id}
                        action="decline"
                        modal_id="draft-decline-modal"
                        handleSubmit={handleSubmit}
                        reason={reason}
                        setReason={setReason}
                        submitting = {submitting}
                        isDeclineButtonDisabled = {isDeclineButtonDisabled}
                      />
                  </div>
                ))}
              </AccordionItem>
            </Accordion>
          </div>
          ))}
        </div>
      ))}
    </div>
  )
}

let Applicants = () => {
  return (
    <div className="wrapper" style={{minWidth: '100%'}}>
      <FetchApplicants/>
    </div>
  );
};


if (document.querySelector("[data-js=business-applicants]")) {
  ReactDOM.render(<Applicants />, document.querySelector("[data-js=business-applicants]"));
}
