import Flash from "../lib/flash";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  FormSection,
  FormSectionTitle,
  PlainFormInfo,
  Textarea,
  ErrorDisplay,
} from "./shared/FormComponents";
import { DateSheetSelector } from "@taprootfoundation/tplus-ui-components";

let SessionRescheduler = function () {
  let [errors, setErrors] = useState({});
  let [fields, setFields] = useState({ reason: "" });
  let [timeSlots, setTimeSlots] = useState([]);
  let [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    let newFields = { ...fields, [e.target.name]: e.target.value };
    setFields(newFields);
  };

  let handleSubmit = (action) => {
    return (e) => {
      setSubmitting(true);
      let command = { ...fields, time_slots: timeSlots };

      fetch(window.app.route, {
        method: "POST",
        body: JSON.stringify({ command }),
        headers: {
          "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.clone().json())
        .then((result) => {
          if (result.errors) {
            Flash.addError(
              "Form has errors; Please resolve the errors and try again"
            );
            setErrors(result.errors);
          } else {
            window.location = result.location;
          }
          setSubmitting(false);
        });
    };
  };

  let goBack = () => {
    return (e) => {
      e.preventDefault();
      window.history.back();
    };
  };

  let CancelButton = () => {
    if (window.app.editSession) {
      return (
        <button
          onClick={goBack()}
          className="btn-trp ghost full blue clarke button-unbind"
          disabled={submitting}
        >
          {submitting ? "Saving..." : "Cancel"}
        </button>
      );
    } else {
      return <p></p>;
    }
  };

  return (
    <div>
      <div className="card auto-width">
        <div className="l-row">
          <div className="l-col">
            <FormSection>
              <FormSectionTitle big title="Propose new times" />
              {window.app.session_first_name && (
                <PlainFormInfo
                  full
                  text={`Write a message to ${window.app.session_first_name}  to reschedule this call.`}
                />
              )}
              <ErrorDisplay errors={errors} field="reason">
                <Textarea
                  value={fields.reason}
                  onChange={handleChange}
                  name="reason"
                  placeholder="Your reason for rescheduling"
                  error={errors["reason"]}
                />
              </ErrorDisplay>
            </FormSection>
          </div>
        </div>
      </div>
      <div className="card auto-width">
        <div className="l-row">
          <div className="l-col">
            <div className="info-trp plain">
              <h1 className="page-title ">Your Availability</h1>
              <p>
                Click the calendar below to propose new times for the 1-hour
                consultation session. We recommend selecting 5 or more times
                over at least 3 different days.
              </p>
            </div>
            <PlainFormInfo
              text="* Timezone is adjusted automatically based on your IP address"
              full
              plain
            />
            <FormSection>
              <ErrorDisplay errors={errors} field="time_slots">
                <DateSheetSelector
                  name="time_slots"
                  onChange={setTimeSlots}
                  timeslots={[]}
                  blockedDays={2}
                  unavailableTimes={window.app.booked_times}
                />
              </ErrorDisplay>
            </FormSection>
            <FormSection>
              <div className="l-row">
                <div className="l-col">
                  <button
                    onClick={handleSubmit("submit")}
                    className={`btn-trp full clarke button-unbind ${
                      submitting ? "is-inactive" : "blue"
                    }`}
                  >
                    Submit
                  </button>
                </div>
                <div className="l-col">
                  <CancelButton></CancelButton>
                </div>
              </div>
            </FormSection>
          </div>
        </div>
      </div>
    </div>
  );
};

if (document.querySelector("[data-js=session-rescheduler]")) {
  ReactDOM.render(
    <SessionRescheduler />,
    document.querySelector("[data-js=session-rescheduler]")
  );
}
