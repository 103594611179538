import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import {ApplicantTab} from "./applicants/applicant-tab";
import {ApplicantItem} from "./applicants/applicant-item";
import {AcceptModal} from "./modals/accept-modal";
import {DeclineModal} from "./modals/decline-modal";
import Flash from "../lib/flash";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

const FetchApplicants = ({itemsPerPage}) => {
  const [engagements, setEngagements] = useState([]);
  const [data, setData] = useState({});
  const [showMoreText, setShowMoreText] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [isDeclineButtonDisabled, setIsDeclineButtonDisabled] = useState(false);
  const [time, setTime] = useState("");
  const [reason, setReason] = useState( "");
  const [inquiryId, setInquiryId] = useState("");

  // pagination
  const items = typeof engagements !=='undefined' && Object.keys(engagements).length > 0 ?  engagements : [];
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  
  const handlePageNumberClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const toggleClass = () => {
    setShowMoreText(!showMoreText);
  };

  let handleOpenAcceptModal = (id) => {
    let acceptModal = new Foundation.Reveal($(`#draft-confirm-modal${id}`));
    acceptModal.open();
  }

  let handleOpenDeclineModal = (id) => {
    let declineModal = new Foundation.Reveal($(`#draft-decline-modal${id}`));
    declineModal.open();
  }

  useEffect(() => {
    if (time === "") {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [time]);

  useEffect(() => {
    if (reason === "") {
      setIsDeclineButtonDisabled(true);
    } else {
      setIsDeclineButtonDisabled(false);
    }
  }, [reason]);
  
  let handleProjectSubmit = (action, id) =>  {
    setSubmitting(true);
    setIsSubmitButtonDisabled(true);
    setInquiryId(id);
    setReason(reason);

    let url = action === "decline" ? `/nonprofits/inquiries/${id}/reject` : `/nonprofits/inquiries/${id}/confirm`

    // set form data
    let command = new FormData();
    command.append('time_slot', time)
    command.append('commands_event[reason]', reason)

    let page_url = window.location.href

    fetch(url, {
      method: 'POST',
      body: command,
      headers: {
        "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      }
    })
      .then(response => response.clone().json())
      .then(result => {
        if (result) {
          Flash.addMessage(
            result.message
          );
        } else {
          Flash.addError(
            result.errors
          );
        }
        setSubmitting(false);
        window.location= page_url;
        window.location.reload()
      });
  };

  let handleSessionSubmit = (action, id) =>  {
    setSubmitting(true);
    setIsSubmitButtonDisabled(true);
    setInquiryId(id);
    setReason(reason);

    let url = action === "decline" ? `/nonprofits/sessions/inquiries/${id}/reject` : `/nonprofits/sessions/inquiries/${id}/confirm`

    // set form data
    let command = new FormData();
    command.append('time', time)
    command.append('commands_event[reason]', reason)

    let page_url = window.location.href

    fetch(url, {
      method: 'POST',
      body: command,
      headers: {
        "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      }
    })
      .then(response => response.clone().json())
      .then(result => {
        if (result) {
          Flash.addMessage(
            result.message
          );
        } else {
          Flash.addError(
            result.errors
          );
        }
        setSubmitting(false);
        window.location= page_url;
        window.location.reload()
      });
  };
  
  useEffect(() => {
    fetch('/nonprofits/applicants',
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
      
      )
      .then(response => {
        return response.clone().json()
      })
      .then(result => {
        setEngagements(result)
      });
  }, []);
  
  useEffect(() => {
    let _data = {
      inquiries: []
    };
    if(engagements.length) {
      _data = engagements
      const _engagement = [
        {
          engagement_list: _data,
        }
      ]
      setData(_engagement);
    }
  }, [engagements])
  
  return(
    <div>
        {currentItems.length && currentItems.map(({description, inquiries, url, engagement_type, }, key) => (
          <div key={key}>
            <Accordion allowZeroExpanded preExpanded={['item0']}>
              <AccordionItem key={key} uuid={"item"+key}>
                <div className="l-row full">
                  <h4 className="page-title expand" style={{fontSize: 20}}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <ApplicantTab
                          description={description}
                          inquiries={inquiries}
                        />
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </h4>
                </div>
              {inquiries.length && inquiries.map(inquiry => (
                <div key={key++}>
                  <AccordionItemPanel>
                    <section>
                      <ApplicantItem
                        inquiries={inquiry}
                        handleOpenAcceptModal={handleOpenAcceptModal}
                        handleOpenDeclineModal={handleOpenDeclineModal}
                        applicantIndex={key}
                        showMoreText={showMoreText}
                        toggleClass={toggleClass}
                        view_more_url={url}
                        engagement_type ={engagement_type}
                        action="confirm"
                        id = {inquiry.id}
                      />
                  </section>
                </AccordionItemPanel>
                  {inquiry && inquiry.time_slots ?
                    <AcceptModal
                      modal_id="draft-confirm-modal"
                      action = "submit"
                      reschedule_url={inquiry.reschedule_url}
                      inquiryId={inquiry.id}
                      header={inquiry.user.name.split(" ")[0]+'\'s Availability'}
                      content="Choose from the time slots below for when you're available for the interview"
                      timeslots={inquiry && inquiry.time_slots ? inquiry.time_slots : []}
                      time_slots_formatted={inquiry && inquiry.time_slots_formatted ? inquiry.time_slots_formatted : []}
                      handleSubmit = {engagement_type === 'project' ? handleProjectSubmit : handleSessionSubmit}
                      setTime = {setTime}
                      submitting = {submitting}
                      isSubmitButtonDisabled = {isSubmitButtonDisabled}
                    />
                    : ""}
                  <DeclineModal
                    header = "Confirm Decline"
                    content = "Please note that this will be sent directly to "
                    name = {inquiry.user.name}
                    inquiryId= {inquiry.id}
                    action="decline"
                    modal_id="draft-decline-modal"
                    handleSubmit={engagement_type === 'project' ? handleProjectSubmit : handleSessionSubmit}
                    reason={reason}
                    setReason={setReason}
                    submitting = {submitting}
                    isDeclineButtonDisabled = {isDeclineButtonDisabled}
                  />
                </div>
            ))}
          </AccordionItem>
        </Accordion>
        </div>
        ))}

      <ReactPaginate
        nextLabel="Next >>"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        onPageChange={handlePageNumberClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        containerClassName="pagination"
        activeClassName="active"
        activeLinkClassName="active"
        previousLabel="<< Previous"
        renderOnZeroPageCount={null}
      />
    </div>
  )
}

let Applicants = () => {
  return (
    <div className="wrapper" style={{minWidth: '100%'}}>
      <FetchApplicants itemsPerPage={10}/>
    </div>
  );
};

if (document.querySelector("[data-js=nonprofit-applicants]")) {
    ReactDOM.render(<Applicants />, document.querySelector("[data-js=nonprofit-applicants]"));
  }
