import React from 'react';

export const ActionButtons = (props) => {
  const inquiry = props.inquiries
  if (inquiry.state==="Applied"){
    return (
      <div className="l-row btn-trp-wrapper no-margin" style={{flexWrap: 'nowrap'}}>
        <a className="l-col btn-trp  ghost blue clarke" onClick={()=>props.handleOpenDeclineModal(props.id)} style={{minWidth: 200, minHeight:35, height: 35}} >DECLINE</a>
        { props.engagement_type === "session" ? <a className="l-col btn-trp ghost blue  clarke"  href={inquiry.message_url} target="_blank" style={{minWidth: 200, minHeight:35, height: 35}}>SEND MESSAGE</a> : ''}
        <a className="l-col btn-trp is-active  clarke accept-modal"  onClick={()=>props.handleOpenAcceptModal(props.id)} href="#" style={{minWidth: 200, minHeight:35, height: 35}}>{ props.engagement_type === "session" ? 'ACCEPT' : 'SCHEDULE CALL'}</a>
      </div>
    );
  }  else if (inquiry.state==="Matched" || inquiry.state==="Confirmed" ){
    return (
      <div className="l-row btn-trp-wrapper no-margin"  style={{flexWrap: 'nowrap'}}>
        <a className="l-col btn-trp  ghost blue clarke" onClick={()=>props.handleOpenDeclineModal(props.id)} style={{minWidth: 200, minHeight:35, height: 35}} >DECLINE</a>
        { inquiry.interviewed ? '' : <a className="l-col btn-trp  ghost blue clarke" href={inquiry.reschedule_url} target="_blank" style={{minWidth: 210, minHeight:35, height: 35}} >RESCHEDULE CALL</a> }
        { props.engagement_type === "project" &&  !inquiry.interviewed ? <a className="l-col btn-trp ghost blue clarke"  href={inquiry.message_url} target="_blank" style={{minWidth: 200, minHeight:35, height: 35}}>SEND MESSAGE</a> : props.engagement_type === "project" &&  inquiry.interviewed ?  <a data-method="post" className="btn-trp is-active  clarke"  href={inquiry.accept_url} style={{minWidth: 250, minHeight:35, height: 35}}>WORK WITH {inquiry.user.name.split(" ")[0]}</a> : ''}
      </div>
    );
  } else if (inquiry.state === "Npo rescheduled"){
    return (
      <div className="l-row btn-trp-wrapper no-margin"  style={{flexWrap: 'nowrap'}}>
        <a className="l-col btn-trp  ghost blue clarke" onClick={()=>props.handleOpenDeclineModal(props.id)} style={{minWidth: 200, minHeight:35, height: 35}} >{ props.engagement_type === "session" ? 'CANCEL SESSION' : 'DECLINE' }</a>
        { props.engagement_type === "project" ? <a className="l-col btn-trp  ghost blue clarke" href={inquiry.reschedule_url} target="_blank" style={{minWidth: 210, minHeight:35, height: 35}} >RESCHEDULE CALL</a> : ''}
        { props.engagement_type === "project" ? <a className="l-col btn-trp ghost blue clarke"  href={inquiry.message_url} target="_blank" style={{minWidth: 200, minHeight:35, height: 35}}>SEND MESSAGE</a> : ''}
      </div>
    );
  } else {
    return (
      <div className="btn-trp-wrapper no-margin"> </div>
    );
  }
};
