import Moment from "moment";
import Flash from "../lib/flash";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  FormSection,
  PlainFormInfo,
  Textarea,
  LiTextfield,
  ErrorDisplay,
  ProjectCategories,
  ProjectScoping,
  SkillSet,
  SkillWrapper,
} from "./shared/FormComponents";

let SessionImage = ({ onChange }) => {
  return (
    <div className="l-row">
      <div className="l-col">
        <PlainFormInfo
          title="Select a cover photo for your session."
          text="The most effective photos are large, landscape-orientation photos of people carrying out your organization's mission. Do not use a logo or image with a lot of text unless you absolutely have to."
          plain
        />
        <br />
        {window.app.sessionImageUrl &&
          window.app.sessionImageUrl.length > 0 && (
            <div className="session-image-url">
              <img
                src={window.app.sessionImageUrl}
                className="image_preview"
                alt="session cover uploaded"
              />
            </div>
          )}
        <div className="l-form-wrapper">
          <div>
            <input
              type="file"
              name="image"
              id="image_upload"
              data-url="/presign"
              data-direct="false"
              data-presigned="true"
              data-reference="foo"
              onChange={onChange}
            />
            <p className="info-trp plain small">
              JPG, GIF or PNG. Max size of 10MB{" "}
            </p>
            <input
              type="hidden"
              name="image_id"
              id="image_id"
              data-reference="foo"
            />
          </div>

          <input
            data-reference="foo"
            id="image_id"
            name="image_id"
            type="hidden"
          />
        </div>
      </div>
    </div>
  );
};

let SessionScheduler = () => {
  const [errors, setErrors] = useState({});
  const [skill, setSkill] = useState("");
  const [selectedSkills, setSelectedSkills] = useState(
    window.app.selected_skills || []
  );
  const [projectScope, setProjectScope] = useState(
    window.app.advice_type || ""
  );
  const [projectCategoryId, setProjectCategoryId] = useState(
    window.app.category || ""
  );
  const [description, setDescripiton] = useState(window.app.description || "");
  const [linkedInId, setLinkedInId] = useState(window.app.linkedin_id || "");
  const [sessionImage, setSessionImage] = useState(null);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  let handleSetProjectCategory = (categoryId) => {
    setProjectCategoryId(categoryId);
    setSelectedSkills((arr) => []);
  };

  const handleAddSkill = (skill) => {
    setSelectedSkills((arr) => [...arr, skill]);
  };

  let [timeSlots, setTimeSlots] = useState(() => {
    if (!window.app) return [];
    let slots = window.app.time_slots ? window.app.time_slots : [];
    if (slots.length == 0) return [];
    return slots.map((slot) => Moment(slot));
  });

  let [submitting, setSubmitting] = useState(false);

  let [availabilities, setAvailabilities] = useState([]);

  let uploadImage = (e) => {
    setSessionImage(e.target.files[0]);
  };

  useEffect(() => {
    fetch("/api/app/nonprofits/sessions/availabilities")
      .then((response) => response.clone().json())
      .then((times) => {
        setAvailabilities(times.records);
      });
  }, [availabilities.length]);

  let redirectTo = (result) => {
    if (result.record.location) {
      window.location = result.record.location;
    } else if (window.app.userType === undefined) {
      window.location = "/nonprofits/sessions/" + result.record.id;
    } else {
      window.location =
        "/" + window.app.userType + "/sessions/" + result.record.id;
    }
  };

  useEffect(() => {
    if (projectScope === "" || projectCategoryId === "" || description === "") {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [projectCategoryId, projectScope, description]);

  // updated to curry function to avoid invocation at every render and to fix the issue where action was not getting set on click
  let handleSubmit = (action) => (event) => {
    setSubmitting(true);
    setIsSubmitButtonDisabled(true);

    // set form data
    let command = new FormData();
    command.append("command[advice_type]", projectScope);
    command.append("command[skills]", $("[name='command[skills]']").val());
    command.append("command[description]", description);
    command.append("command[project_category_id]", projectCategoryId);
    if (sessionImage) command.append("command[image]", sessionImage);
    command.append("command[draft]", action == "draft");

    fetch(window.app.route, {
      method: window.app.editSession ? "PUT" : "POST",
      body: command,
      headers: {
        "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      },
    })
      .then((response) => response.clone().json())
      .then((result) => {
        if (result.record) {
          if (result.record.location) {
            window.location = result.record.location;
          } else {
            redirectTo(result);
          }
        } else {
          Flash.addError(
            "Form has errors; Please resolve the errors and try again"
          );
          setErrors(result.errors);
        }
        setSubmitting(false);
      });
  };

  let goBack = () => {
    return (e) => {
      e.preventDefault();
      window.history.back();
    };
  };

  let SubmitButton = () => {
    if (window.app.editSession) {
      return (
        <button
          onClick={handleSubmit("submit")}
          className={`btn-trp clarke button-unbind ${
            submitting ? "is-inactive" : "blue"
          }`}
          disabled={submitting}
        >
          {submitting
            ? "Submitting..."
            : window.app.session_state === "completed" ||
              window.app.session_state === "cancelled"
            ? "Submit"
            : "Publish Session"}
        </button>
      );
    } else {
      return (
        <button
          id="submit-id"
          onClick={handleSubmit("submit")}
          className={`btn-trp clarke button-unbind ${
            isSubmitButtonDisabled ? "is-inactive" : "blue"
          }`}
          disabled={isSubmitButtonDisabled ? "disabled" : ""}
        >
          {submitting ? "Submitting..." : "Submit"}
        </button>
      );
    }
  };
  let CancelButton = () => {
    if (window.app.editSession) {
      return (
        <button
          onClick={goBack()}
          className="btn-trp ghost blue clarke button-unbind"
          disabled={submitting}
        >
          {submitting ? "Saving..." : "Cancel"}
        </button>
      );
    } else {
      return <p></p>;
    }
  };

  let openModal = () => {
    const draftModal = new Foundation.Reveal($("#draft-confirm-modal"));
    draftModal.open();
  };

  let Modal = ({ header, content }) => {
    return (
      <div>
        <div
          id="draft-confirm-modal"
          className="reveal"
          aria-hidden="true"
          aria-labelledby="modalTitle"
          data-reveal="false"
          role="dialog"
        >
          <div className="l-page-title">
            <div className="project-info-box yellow">
              <div className="page-header">
                <div className="l-page-title">
                  <div className="page-title clarke centered">{header}</div>
                  <br />
                  <br />
                  <br />
                  <div
                    className="centered"
                    style={{ fontSize: "16px", lineHeight: "26px" }}
                  >
                    {content}
                  </div>
                </div>
              </div>
              <div className="l-row">
                <div className="l-col">
                  <br />
                  <br />
                  <div className="btn-trp-wrapper no-margin">
                    <button
                      onClick={handleSubmit("draft")}
                      className="btn-trp ghost blue clarke button-unbind"
                      disabled={submitting}
                    >
                      {"Save as Draft"}
                    </button>
                    <button
                      onClick={handleSubmit("submit")}
                      className={`btn-trp clarke button-unbind ${
                        submitting ? "is-inactive" : "blue"
                      }`}
                      disabled={submitting}
                    >
                      {submitting ? "Submitting..." : "Publish Session"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="section">
        <div className="l-page-title">
          <a href="#" id="goback" style={{ position: "relative" }}>
            &lt;
          </a>
          <h1 className="page-title circular">Session Request Form</h1>
        </div>
        <br />
        <div className="info-trp plain">
          <p>
            Fill out this form to submit your request so our skilled volunteers
            know what you need help with. Please keep in mind that all
            information you provide here will be shared publicly with potential
            volunteer candidates.
          </p>
        </div>
        <br />
        <FormSection>
          <PlainFormInfo
            title="Which area of expertise do you need support in?"
            plain
          />
          <ErrorDisplay errors={errors} field="project_category_id">
            <ProjectCategories
              defaultCat={projectCategoryId}
              onChange={(e) => handleSetProjectCategory(e.target.value)}
              required={true}
              error={errors["project_category_id"]}
            />
          </ErrorDisplay>
        </FormSection>
        <FormSection>
          <PlainFormInfo
            title="What kind of advice are you looking for?"
            plain
          />
          <ErrorDisplay errors={errors} field="advice_type">
            <ProjectScoping
              value={projectScope}
              onChange={(e) => setProjectScope(e.target.value)}
              required={true}
              error={errors["advice_type"]}
            />
          </ErrorDisplay>
        </FormSection>
        <FormSection>
          <PlainFormInfo title="What skills are most important to you?" plain />
          <ErrorDisplay errors={errors} field="skills">
            <div className="skill-select">
              <SkillSet
                value={skill}
                onChange={(e) => {
                  setSkill(e.target.value);
                  handleAddSkill(e.target.value);
                }}
                categoryId={projectCategoryId}
                required={true}
                error={errors["skills"]}
              />
              <SkillWrapper name="command[skills]" value={selectedSkills} />
            </div>
          </ErrorDisplay>
        </FormSection>
        <FormSection>
          <PlainFormInfo
            title={window.app.sessionTopic}
            text={window.app.sessionTopicSubheading}
          />
          <ErrorDisplay errors={errors} field="description">
            <Textarea
              value={description}
              onChange={(e) => setDescripiton(e.target.value)}
              name="description"
              error={errors["description"]}
              dataCounter="enabled"
              dataMinChars="0"
              dataMaxChars="250"
              placeholder="Please write a short paragraph about your goal for this consultation session"
            />
          </ErrorDisplay>
        </FormSection>

        <FormSection>
          <PlainFormInfo
            title={"LinkedIn Company ID"}
          />
          <p className="info-trp plain">Please provide your LinkedIn Company ID if you'd like Taproot to recruit volunteers from LinkedIn on your behalf. <a href="https://tpfaq.groovehq.com/help/how-does-taproot-work-with-linkedin-to-promote-my-volunteer-needs">Learn more about leveraging Taproot's partnership with LinkedIn</a> to promote this Taproot Plus listing (free of cost) to relevant LinkedIn users.</p>
          <ErrorDisplay errors={errors} field="linkedin_id">
            <LiTextfield
              value={linkedInId}
              onChange={(e) => setLinkedInId(e.target.value)}
              name="linkedin_id"
            />
          </ErrorDisplay>
        </FormSection>
      </div>

      <div className="section">
        <FormSection>
          <ErrorDisplay errors={errors} field="image_id">
            <SessionImage onChange={uploadImage} />
          </ErrorDisplay>
        </FormSection>
      </div>
      <div className="section footer-section">
        <FormSection>
          <div className="btn-trp-wrapper">
            <SubmitButton></SubmitButton>
            <button
              className="btn-trp ghost blue clarke button-unbind"
              disabled={submitting}
              onClick={openModal}
            >
              {submitting
                ? "Saving..."
                : window.app.session_state === "completed" ||
                  window.app.session_state === "cancelled"
                ? "Save Draft"
                : window.app.editSession
                ? "Update Draft"
                : "Save Draft"}
            </button>
            {window.app.session_state !== "completed" &&
            window.app.session_state !== "cancelled" ? (
              <CancelButton></CancelButton>
            ) : (
              ""
            )}
          </div>
          <Modal
            header="Are you sure?"
            content="Would you like to save this Session as a draft? If you're ready to begin recruiting volunteers, choose to publish your session instead!"
          />
        </FormSection>
      </div>
    </div>
  );
};

if (document.querySelector("[data-js=session-form]")) {
  ReactDOM.render(
    <SessionScheduler />,
    document.querySelector("[data-js=session-form]")
  );
}
