import Angular from "angular";
import Flash from "../lib/flash";

let app = Angular.module("availability", []);

app.config(["$httpProvider", ($httpProvider) => {
  $httpProvider.defaults.headers.common["X-CSRF-Token"] = $("meta[name=csrf-token]").attr("content");
}]);

app.controller("main", ["$scope", "$http", ($scope, $http) => {
  $scope.models = {
    blob: [{},{},{},{},{},{},{}],
  };
  $scope.errors = {};

  $http({
    method: "GET",
    url: "/api/app/consultants/availability",
  }).then((data) => {
    let blob = data.data.record.blob;
    if (blob.length === 7) {
      $scope.models.blob = blob;
    }
  });

  $scope.toggleTime = (a, b) => {
    $scope.models.blob[a][b] = !$scope.models.blob[a][b]
  }

  $scope.submit = () => {
    $scope.submitting = true;
    $scope.errors = {};
    $http({
      method: "PATCH",
      data: { command: $scope.models.blob },
      url: "/api/app/consultants/availability",
    }).then((data) => {
      $scope.submitting = false;
      if (data.data.location) {
        window.location = data.data.location;
      } else {
        window.location = window.app.redirect;
      }
    }, (data) => {
      $scope.submitting = false;
      $scope.errors = data.data.errors;
      for (let key of Object.keys($scope.errors)) {
        Flash.addError($scope.errors[key][0]);
      }
    });
  }
}]);

export default () => { }
