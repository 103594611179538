/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import { Foundation } from "foundation-sites/js/foundation.core";
import { Reveal } from "foundation-sites/js/foundation.reveal";
import "script-loader!moment";
import "script-loader!daterangepicker";
import "chart.js";
import "chartkick";
import MinimumFeatures from "../utilities/minimum-features";
import TimeZone from "../utilities/time-zone";
import SocialButtons from "../utilities/social-buttons";
import "../modules/project-scheduler";
import "../modules/session-scheduler";
import "../modules/session-rescheduler";
import "../modules/nonprofit-applicants";
import "../modules/business-applicants";
import "../modules/apply_now";
import "../modules/webinar-countdown";
import Availability from "../modules/availability";
import Flash from "../lib/flash";
import "script-loader!passwordStrength";
import "../views/nonprofits";
import "../views/consultants";

require("@rails/activestorage").start();

class App {
  init(doc) {
    setTimeout(() => {
      this.initUnsupportedBrowsers(doc);
    });
    this.initFoundation(doc);
    this.initTimeZone(doc, window.jstz);
    this.initSocialButtons(doc);
    // ProjectScheduler();
    // SessionScheduler();
    // SessionRescheduler();
    this.initBackButton(doc);
    this.initNestedButton(doc);
    this.initNestedNav(doc);
    Availability();
  }

  initFoundation(doc) {
    Foundation.addToJquery($);
    jQuery(doc).foundation();
  }

  initUnsupportedBrowsers(doc) {
    const detector = new MinimumFeatures(doc);
    if (!detector.dataSupport()) {
      detector.shimData();
    }
    if (!(detector.pass() || detector.isOnMinimumFeaturesPage())) {
      detector.showMessage();
    }
  }

  initTimeZone(doc, jstz) {
    new TimeZone(doc, jstz).setCookie();
  }

  initSocialButtons(doc) {
    SocialButtons.initAll(doc);
  }

  addFlash(attrs) {
    if (attrs.kind === "alert") {
      Flash.addError(attrs.text);
    } else {
      Flash.addMessage(attrs.text);
    }
  }

  initBackButton(doc) {
    $("a#goback").on("click", (e) => {
      e.preventDefault();
      window.history.back();
    });
  }

  initNestedButton(doc) {
    $("div.nested-menu > a").on("click", function (e) {
      $(this).siblings("ul").slideToggle();
    });
  }

  initNestedNav(doc) {
    $("li.with_nested_links > span").on("click", function (e) {
      $(this).parent().toggleClass("is-active");
    });
  }
}

window.application = new App();
window.application.init(document);
