import React, { useEffect } from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { InfoIcon } from "../shared/svg_icons";
import EventCard from "./components/event_card";

const Container = styled.div``;

const Header = styled.div`
  padding: 40px;
  padding-top: 24px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(174, 174, 174, 0.5);
  margin-bottom: 40px;

  .title {
    font-family: "Circular";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.015em;
    color: #4d4d4d;
  }
`;

function IndexPage() {
  const [data, setData] = useState([]);
  const [totalValue, setTotalValue] = useState("...");

  useEffect(() => {
    fetch(`/consultants/events.json`)
      .then((response) => {
        return response.clone().json();
      })
      .then((data) => {
        setTotalValue(data.total_value);
        setData(data.events);
      });
  }, []);

  const rows = data.map((item, index) => {
    return <EventCard key={index} eventData={item} />;
  });

  return (
    <Container>
      <Header>
        <div className="title">Events</div>
        <div className="value">
          Total Hours Participated: <b>{totalValue}</b>
        </div>
      </Header>
      {rows ? rows : <InfoIcon />}
    </Container>
  );
}

if (document.querySelector("[data-js=vol-events-index]")) {
  ReactDOM.render(
    <IndexPage />,
    document.querySelector("[data-js=vol-events-index]")
  );
}
