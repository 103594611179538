import React from "react";
import ReactDOM from "react-dom";


function getTimeInZone(time) {
  var tz = ((new Date()).getTimezoneOffset() / -60)
  
  var less = Math.abs(tz) < 10
  var lessVal = less ? `0${Math.abs(tz)}` : Math.abs(tz).toString();
  tz = tz < 0 ? `GMT-${lessVal}00` : `GMT+${lessVal}00`
  
  var formattedTime = (new Date(`${time}`)).toLocaleString("en-US", tz)
  return new Date(formattedTime)
}

const constructInterval = (start_time) => {
  const today = new Date();
  const future = start_time;

  let interval = future - today;

  const days = Math.floor(interval / (3600 * 24 * 1000));

  interval -= days * 3600 * 24 * 1000;

  const hours = Math.floor(interval / (3600 * 1000));

  interval -= hours * 3600 * 1000;

  const minutes = Math.floor(interval / (60 * 1000));

  interval -= minutes * 60 * 1000;

  const seconds = Math.floor(interval / 1000);

  return [{ type: 'days', value: days}, { type: 'hr', value: hours}, { type: 'min', value: minutes}, {type: 'sec', value: seconds}];
};

const TimePart = ({ value, type }) => {
  return (
    <div className='time-part'>
      <span className='value'>{value < 10 ? `0${value}` : value}</span>
      <span className='type'>{type}</span>
    </div>
  )
}

class WebinarCountdown extends React.Component {
  componentDidMount() {
    this.interval = window.setInterval(() => this.setState({}), 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  render() {
    const now = new Date();
    const { end_time, start_time } = this.props;

    if(now > end_time) return <span>Completed</span>;

    let data = constructInterval(start_time);

    if(now > start_time && now < end_time) data = constructInterval(end_time);

    return (
      <>
      {now > start_time && now < end_time ? <span>In Progress</span> : 'Before start'}
      <div className='time-parts'>
        {
          data.map((part, index) => <TimePart key={index} {...part} />)
        }
      </div>
      </>
    );
  }
}

if (document.querySelector("#webinar-countdown")) {
  const a = JSON.parse(
    document.querySelector("#webinar-countdown").dataset.webinar
  );

  ReactDOM.render(
    <WebinarCountdown start_time={getTimeInZone(a.start_time)} end_time={getTimeInZone(a.end_time)} />,
    document.querySelector("#webinar-countdown")
  );
}
