import Flash from "../lib/flash";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  FormSection,
  FormSectionTitle,
  PlainFormInfo,
  FormCheckbox,
  ErrorDisplay,
  Textarea,
} from "./shared/FormComponents";
import { DateSheetSelector } from "@taprootfoundation/tplus-ui-components";

const ProjectScheduler = () => {
  let [errors, setErrors] = useState({});
  let [timeSlots, setTimeSlots] = useState([]);
  let [fields, setFields] = useState({
    experience: false,
    start: false,
    deadlines: false,
    qualifications: "",
  });

  const handleCheckbox = (field) => {
    return (e) => {
      let newFields = { ...fields, [field]: e.target.checked };
      setFields(newFields);
    };
  };

  const handleChange = (e) => {
    let newFields = { ...fields, [e.target.name]: e.target.value };
    setFields(newFields);
  };

  const handleSubmit = () => {
    let command = { ...fields, time_slots: timeSlots };
    fetch(
      "/api/app/consultants/projects/" + window.app.project_id + "/inquire",
      {
        method: "POST",
        body: JSON.stringify({ command }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
        },
      }
    )
      .then((response) => response.clone().json())
      .then((result) => {
        if (result.errors) {
          Flash.addError("Form has errors; Pls resolve and try again");
          setErrors(result.errors);
        } else {
          window.location = result.location;
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div className="wrapper project-detail no-bottom">
          <FormSection>
            <FormSectionTitle big title="Let's make sure you're a great fit!" />
            <PlainFormInfo
              text="Thank you for your willingness to donate your time and talent.
          We want all parties to have the best possible outcome on Taproot Plus, and so we are seeking a certain level of experience and readiness in our skilled volunteers.
          Please confirm you're a good fit:"
              full
              plain
            />

            <ErrorDisplay errors={errors} field="experience">
              <FormCheckbox
                onChange={handleCheckbox}
                labelText={
                  <>
                    I have at least 3 years of professional experience in the
                    skills needed for this project.
                  </>
                }
                name="experience"
                value={fields.experience}
                error={Object.keys(errors).includes("experience")}
                type="project"
              />
            </ErrorDisplay>
            <ErrorDisplay errors={errors} field="start">
              <FormCheckbox
                onChange={handleCheckbox}
                labelText="I am prepared to start this project now, and dedicate at least 2 hours/week until the project is complete"
                name="start"
                value={fields.start}
                error={Object.keys(errors).includes("start")}
                type="project"
              />
            </ErrorDisplay>
            <ErrorDisplay errors={errors} field="deadlines">
              <FormCheckbox
                onChange={handleCheckbox}
                labelText="I am prepared to treat this like any consulting engagement. I'll plan ahead, stick to deadlines the best I can, and communicate in a timely fashion"
                name="deadlines"
                value={fields.deadlines}
                error={Object.keys(errors).includes("deadlines")}
                type="project"
              />
            </ErrorDisplay>
          </FormSection>
        </div>
      </div>
      <div className="container">
        <div className="wrapper project-detail no-bottom">
          <FormSection>
            <PlainFormInfo full plain title={window.app.fitness} />
            <PlainFormInfo
              text="Please write a short paragraph about why your experience will make this project successful"
              full
              required={true}
            />
            <ErrorDisplay errors={errors} field="qualifications">
              <Textarea
                name="qualifications"
                onChange={handleChange}
                error={Object.keys(errors).includes("qualifications")}
              />
            </ErrorDisplay>
          </FormSection>
        </div>
      </div>
      <div className="container">
        <div className="wrapper project-detail no-bottom">
          <FormSection>
            <PlainFormInfo full plain title="Your Availability" />
            <PlainFormInfo
              text="Select 5 or more times over at least 3 days for a phone call with the nonprofit contact."
              full
              plain
            />
            <ErrorDisplay field="time_slots" errors={errors}>
              <DateSheetSelector
                name="time_slots"
                onChange={setTimeSlots}
                timeslots={[]}
                blockedDays={2}
                unavailableTimes={window.app.booked_times}
              />
            </ErrorDisplay>
          </FormSection>
        </div>
      </div>
      <div className="container apply_now">
        <div className="wrapper project-detail grey-card no-top">
          <FormSection>
            <div className="l-row">
              <div className="l-col">
                <button
                  onClick={handleSubmit}
                  className="btn-trp is-active full"
                >
                  {" "}
                  Apply{" "}
                </button>
              </div>
              <div className="l-col"></div>
            </div>
          </FormSection>
        </div>
      </div>
    </div>
  );
};

if (document.querySelector("[data-js=project-scheduler]")) {
  ReactDOM.render(
    <ProjectScheduler />,
    document.querySelector("[data-js=project-scheduler]")
  );
}

const ProjectRescheduler = () => {
  let [errors, setErrors] = useState({});
  let [fields, setFields] = useState({ reason: "" });
  let [timeSlots, setTimeSlots] = useState([]);
  let [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    let newFields = { ...fields, [e.target.name]: e.target.value };
    setFields(newFields);
  };

  let handleSubmit = (action) => {
    return (e) => {
      setSubmitting(true);
      let command = { ...fields, time_slots: timeSlots };

      fetch(window.app.route, {
        method: "POST",
        body: JSON.stringify({ command }),
        headers: {
          "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.clone().json())
        .then((result) => {
          if (result.errors) {
            Flash.addError(
              "Form has errors; Please resolve the errors and try again"
            );
            setErrors(result.errors);
          } else {
            window.location = result.location;
          }
          setSubmitting(false);
        });
    };
  };

  return (
    <>
      <FormSection>
        {window.app.inquiryMissed ? (
          <>
            <FormSectionTitle
              big
              title={`Pick times to retry your call with ${window.app.orgName}`}
            />
            <PlainFormInfo
              full
              text={`If you take no action, ${window.app.orgName} will be asked to select times.`}
            />
          </>
        ) : (
          <FormSectionTitle
            big
            title={`Propose new times for your call with ${window.app.orgName}`}
          />
        )}
        {window.app.orgName && (
          <PlainFormInfo
            full
            plain
            title={`What was the reason for rescheduling?`}
            text={`Please note this will be sent directly to ${window.app.orgName}`}
          />
        )}
        <ErrorDisplay errors={errors} field="reason">
          <Textarea
            value={fields.reason}
            onChange={handleChange}
            name="reason"
            placeholder="Your reason for rescheduling"
          />
        </ErrorDisplay>
      </FormSection>
      <FormSection>
        <ErrorDisplay field="time_slots" errors={errors}>
          <DateSheetSelector
            name="time_slots"
            onChange={setTimeSlots}
            timeslots={[]}
            blockedDays={2}
            unavailableTimes={window.app.booked_times}
          />
        </ErrorDisplay>
      </FormSection>
      <FormSection>
        <button
          onClick={handleSubmit("submit")}
          className={`btn-trp clarke ${submitting ? "" : "is-active"}`}
        >
          Send Times
        </button>
      </FormSection>
    </>
  );
};

if (document.querySelector("[data-js=project-rescheduler]")) {
  ReactDOM.render(
    <ProjectRescheduler />,
    document.querySelector("[data-js=project-rescheduler]")
  );
}
