import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CalendarIcon, DollarIcon, InfoIcon } from "../../shared/svg_icons";

const Container = styled.div`
  padding: 24px;
  background: #ffffff;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 34px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  min-height: 210px;

  > div {
    width: 30%;
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  #upper,
  #lower {
    font-family: "circular";
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #575858;

    .eventTitle {
      font-weight: 700;
      font-size: 20px;
      margin-top: 8px;
    }

    .blue {
      font-size: 14px;
      line-height: 20px;
      color: #007abe;
    }
  }

  #upper {
    padding-right: 8px;
  }

  #lower {
    margin-top: 8px;
  }

  #calendarDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #cacaca;
    border-right: 1px solid #cacaca;
    padding: 0px 16px;

    div {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px 0px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }

  #valueDetails {
    font-family: "circular";
    font-style: normal;
    font-weight: 450;
    letter-spacing: 0.015em;
    padding: 0px 8px;

    .value {
      font-size: 40px;
      line-height: 51px;
      color: #4d4d4d;
    }

    .skill {
      font-family: "circular";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      letter-spacing: 0.015em;
      color: #4d4d4d;
      padding: 8px 16px;
      background: #d8d8d7;
      margin-bottom: 10px;
      margin-right: 8px;
      display: inline-block;

      &:last-child {
        margin-right: unset;
      }
    }
  }

  .small {
    font-size: 12px;
    line-height: 14px;
    color: #4d4d4d;
  }
`;

export default function EventCard(props) {
  const { eventData } = props;
  const eventSkills = eventData.skills.map((item, index) => {
    return (
      <span key={index} className="skill">
        {item}
      </span>
    );
  });

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  return (
    <Container>
      <div>
        <div id="upper">
          <div className="eventTitle">{eventData.title}</div>
          <div>{truncateString(eventData.description, 80)}</div>
          <div className="blue">{eventData.program_type}</div>
        </div>
        <div id="lower">
          <div className="blue">{eventData.state}</div>
        </div>
      </div>
      <div id="calendarDetails">
        <div>
          <div>
            <CalendarIcon /> {eventData.date}
          </div>
          <div>
            <DollarIcon /> ${eventData.value}
          </div>
          <div>
            <InfoIcon /> {eventData.category}
          </div>
        </div>
        <div className="small">{eventData.participant_type.toUpperCase()}</div>
      </div>
      <div id="valueDetails">{eventSkills}</div>
    </Container>
  );
}

EventCard.defaultProps = {
  eventData: {
    title: "...",
    description: "...",
    program_type: "...",
    state: "...",
    date: "...",
    value: "...",
    category: "...",
    participant_type: "...",
    skills: ["..."],
  },
};

EventCard.propTypes = {
  eventData: PropTypes.shape({ title: PropTypes.string }),
};
