import Dom from "../lib/dom";

export default class SocialButtons {
  static openWindow(url, title) {
    let width = 800;
    let height = 530;
    let sizeString = "width=" + width + ", height=" + height + ", resizable=yes, scrollbars=yes, status=yes";
    window.open(
      url,
      title,
      sizeString
    );
  }
  static initAll(doc) {
    Dom.querySelectorAll(doc, ".button-social").forEach(function(el) {
      el.addEventListener("click", function(e) {
        e.preventDefault();
        SocialButtons.openWindow(e.target.href, "share");
      });
    });
  }
}
