import React from "react";

export const AcceptModal = ({ header, content, timeslots, inquiryId, reschedule_url, action, modal_id, handleSubmit, time_slots_formatted, setTime, submitting, isSubmitButtonDisabled}) => {
  return (
    <div>
      <div id={modal_id+inquiryId} aria-roledescription={`confirmation-modal-${inquiryId}`} className="reveal" aria-hidden="true" aria-labelledby="modalTitle" data-reveal="false" role="dialog">
        <div className="l-page-title">
          <div className="project-info-box yellow">
            <div className="page-header">
              <div className="l-page-title">
                <div className="page-title  centered">
                  {header}
                </div>
                <br/><br /><br />
                <div className="centered" style={{ "fontSize": "16px", "lineHeight": "26px" }} >
                  {content}
                  {Object.keys(timeslots).map(index=> (
                    <div key={index}>
                      <input id={"ts_"+index} name="time" type="radio" value={timeslots[index]} onChange={e => setTime(e.target.value)}/>
                      <label key={index} htmlFor={`ts_${index}`}>{time_slots_formatted[index]}</label> <br/>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="l-row">
              <div className="l-col">
                <br /><br />
                <div className="btn-trp-wrapper no-margin">
                  <button
                    onClick={()=>handleSubmit('confirm', inquiryId)}
                    className={`btn-trp clarke button-unbind ${isSubmitButtonDisabled ? 'is-inactive' : 'blue is-active'}`}
                    disabled={isSubmitButtonDisabled ? 'disabled' : ''}
                  >
                    {submitting? action+"..." : action}
                  </button>
                  <a className="btn-trp  ghost blue clarke" href={reschedule_url} style={{minWidth: 335, height: 35}} >RESCHEDULE A DIFFERENT CALL TIME</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
