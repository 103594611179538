import React,{useState} from "react";
import {Avatar} from "../shared/avatar";
import {LinkedInIcon, MailIcon} from "../shared/SvgIcons";
import {ActionButtons} from "../buttons/action-buttons";

export const ApplicantItem = ({inquiries, handleOpenAcceptModal, handleOpenDeclineModal, applicantIndex, showMoreText, toggleClass, view_more_url, engagement_type, action, id}) => {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore =(event)=>{
    event.preventDefault();
    setShowMore(!showMore);
  }
  
  const showReadMore = (text) => {
    if (text && text.length > 530) {
      return (
        <div className={showMore ? "" : "read-more"} style={{paddingTop: 6}}>
          <a key={applicantIndex++} className="nav-link dashed inline overview-toggle" style={{borderBottom: 'none'}}
           href="#" onClick={(event) => handleShowMore(event)}>{showMore ? "Show Less" : "Show More"}</a>
        </div> );
    } else {
      return (<div></div>);
    }
  }
  
  return (
    <div className="applicant">
      <div className="card no-shadow auto-width" style={{ marginTop: 2}} >
        <div className="l-row">
          <div className="l-col">
            <div className="profile-pic-group">
              <Avatar className="large-img" name={inquiries.user.name_initials}/>
              <h1 className="title-text" style={{ fontSize: 16, fontWeight: 400, marginLeft: 14}}>
                {inquiries.user.name}
              </h1>
            </div>
          </div>
          <div className="l-col description">
            <div className="project-icon-label">
              <a href={inquiries.user && inquiries.user.linkedin_profile_url ? inquiries.user.linkedin_profile_url : ''} target="_blank">
                <LinkedInIcon linkedin_profile_url={inquiries.user.linkedin_profile_url} className="svg-icon"/>
              </a>
              <MailIcon className="blue svg-icon"/>
              <span className="project-icon-label__text">{inquiries.user.email}</span>
            </div>
          </div>
          <div className="l-col description">
            <div className="project-icon-label">
              <ActionButtons
                id={id}
                action={action}
                inquiries={inquiries}
                handleOpenAcceptModal = {handleOpenAcceptModal}
                handleOpenDeclineModal = {handleOpenDeclineModal}
                engagement_type = {engagement_type}
              />
            </div>
          </div>
        </div>
        <div className="l-row">
          <div className="l-col description">
            <span className="small" style={{marginRight:40, color: '#4D4D4D', fontSize: 12}}>Applied {inquiries.created_at} ago</span>
            <span className="small" style={{color: '#4D4D4D', fontSize: 12}}>{inquiries.user.location}</span>
          </div>
        </div>
        <div className="l-row">
          <div className="project-icon-label">
            <span>
              <div key={applicantIndex++} className={showMore ? "" :"text-footer"}>
                <span>
                  <p> { engagement_type ==='session' ? inquiries.interest : engagement_type ==='project' ? inquiries.qualifications : '' } </p>
                </span>
              </div>
              {engagement_type ==='session' ? showReadMore(inquiries.interest) : engagement_type ==='project' ? showReadMore(inquiries.qualifications) : ''}
            </span>
          </div>
        </div>
        <br/>
        <div className="l-row">
          <span className="small project-status" style={{color:'#065784', float: 'left'}}>
            {(inquiries && inquiries.npo_rescheduled && engagement_type==='session') ? 'YOU REQUESTED DIFFERENT CALL TIMES' : (inquiries.state==='Npo rescheduled'&& engagement_type==='project') ? 'YOU ASKED TO RESCHEDULE CALL': inquiries.state==='Rejected' ? 'DECLINED' : inquiries.interviewed ? 'INTERVIEWED' : inquiries.state.toUpperCase()}
          </span>
          <a href={view_more_url} className="small project-status" style={{color:'#007ABE', float: 'right', fontFamily: 'Clarke'}}>VIEW MORE DETAILS</a>
        </div>
      </div>
    </div>
  );
}
