import React from "react";

export const ApplicantTab = ({ description, inquiries}) => {
 return(
    <span  style={{marginLeft:32, color: '#4D4D4D'}}> {description && description.length > 70 ? description.substring(0,70)+'...' : description} 
      <div style={{float: "right", marginRight: 137}}>
        <span className="small project-status" style={{color:'#878787'}}>Volunteer Applicants</span>
        <span className="small project-status" style={{color: '#4D4D4D'}}>&nbsp; {inquiries.length}</span>
      </div>
    </span>
 );
}
