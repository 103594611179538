import React from 'react';
import {FormSection, PlainFormInfo, Textarea} from "../shared/FormComponents";

export const DeclineModal = ({ header, content, inquiryId, action, modal_id , name, handleSubmit, setReason, reason, submitting, isDeclineButtonDisabled}) => {
  return (
    <div>
      <div id={modal_id+inquiryId} aria-roledescription={`decline-modal-${inquiryId}`} className="reveal" aria-hidden="true" aria-labelledby="modalTitle" data-reveal="false" role="dialog">
        <div className="l-page-title">
          <div className="project-info-box yellow" style={{padding: 45}}>
            <div className="page-header">
              <div className="l-page-title">
                <br/><br /><br />
                <FormSection>
                  <PlainFormInfo
                    title={header}
                    full="full"
                    text= {content + name} />
                    <Textarea
                      value={reason}
                      onChange={e => setReason(e.target.value)}
                      name="reason"
                      dataCounter="enabled"
                      dataMinChars="0"
                      dataMaxChars="250"
                      placeholder={`Please let ${name} know why you decided not to move forward with their application`}
                    />
                </FormSection>
              </div>
            </div>
            <div className="l-row">
              <div className="l-col">
                <br /><br />
                <div className="btn-trp-wrapper no-margin">
                  <button
                    onClick={()=>handleSubmit('decline', inquiryId)}
                    className={`btn-trp clarke button-unbind ${isDeclineButtonDisabled ? 'is-inactive' : 'blue is-active'}`}
                    style={{maxWidth: 0}}
                    disabled={isDeclineButtonDisabled ? 'disabled' : ''}
                  >
                    {submitting? action+"..." : action}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
