export default class TimeZone {
  constructor(doc, jstz) {
    this.doc = doc;
    this.jstz = jstz;
  }
  getName() {
    return this.jstz.determine().name();
  }
  setCookie() {
    this.doc.cookie = "client_time_zone=" + this.getName() + ";path=/;domain=." + window.location.hostname.split(".").slice(-2).join(".") + ";";
  }
}
