import React from "react";

export const Avatar = function (props){
    if (props.url){
     return profileImage(props)
    } else {
      return nameInitials(props)
    }
}


let profileImage = function (props){
  return(
      <div className={`profile-pic ${props.className}`} style={{ backgroundImage: url(props.url)}}>
        {props.name}
      </div>
  );
}

let nameInitials = function (props){
  return (
      <div className={`profile-pic ${props.className}`} style={{ width: 48,  height: 48, fontSize: 20, marginTop: -11}}>
        {props.name}
      </div>
  );
}
