import Dom from "../lib/dom";
import Flash from "../lib/flash";

export default class MinimumFeatures {
  constructor(doc) {
    this.doc = doc;
    this.requiredProps = ["flex"];
  }

  pass() {
    return this.cssSupport() && this.jsSupport();
  }

  cssSupport() {
    return this.requiredProps.every((prop)=> {
      return Dom.detectCss(this.doc, prop);
    });
  }

  jsSupport() {
    return Dom.detectJs(this.doc);
  }

  dataSupport() {
    return Dom.detectData(this.doc);
  }

  shimData() {
    Dom.addScript(this.doc, "https://cdn.rawgit.com/agrieser/183702fa751652bf031d/raw/071f18c3049990528e5441053ef41c98df919d58/ie10data.js");
  }

  isOnMinimumFeaturesPage() {
    return window.location.pathname === "/browsers";
  }

  showMessage() {
    Flash.addError("Your browser isn't supported, click here for details", "/browsers");
  }
}
