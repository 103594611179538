import Flash from "../lib/flash";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  FormSection,
  FormSectionTitle,
  PlainFormInfo,
  FormCheckbox,
  ErrorDisplay,
  Textarea,
} from "./shared/FormComponents";
import { DateSheetSelector } from "@taprootfoundation/tplus-ui-components";

const ApplyNowScheduler = () => {
  const [errors, setErrors] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [fields, setFields] = useState({
    experience: false,
    start: false,
    deadlines: false,
    interest: "",
    timezone: "",
  });

  const handleCheckbox = (field) => {
    return (e) => {
      let newFields = { ...fields, [field]: e.target.checked };
      setFields(newFields);
    };
  };

  const handleChange = (e) => {
    let newFields = { ...fields, [e.target.name]: e.target.value };
    setFields(newFields);
  };

  const handleSubmit = () => {
    const command = { ...fields, time_slots: timeSlots };

    fetch(window.app.route, {
      method: "POST",
      body: JSON.stringify({ command }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      },
    })
      .then((response) => response.clone().json())
      .then((result) => {
        if (result.errors) {
          Flash.addError("Form has errors; Pls resolve and try again");
          setErrors(result.errors);
        } else {
          window.location = result.location;
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div className="wrapper project-detail no-bottom top">
          <FormSection>
            <FormSectionTitle big title="Let's make sure you're a great fit!" />
            <PlainFormInfo
              text="Thank you for your willingness to donate your time and talent.
          We want all parties to have the best possible outcome on Taproot Plus, and so we are seeking a certain level of experience and readiness in our skilled volunteers.
          Please confirm you're a good fit:"
              full
              plain
            />

            <ErrorDisplay errors={errors} field="start">
              <FormCheckbox
                onChange={handleCheckbox}
                labelText="I have reviewed the topic statement for this session request."
                name="start"
                value={fields.start}
                error={Object.keys(errors).includes("start")}
                type="session"
              />
            </ErrorDisplay>

            <ErrorDisplay errors={errors} field="experience">
              <FormCheckbox
                onChange={handleCheckbox}
                labelText={
                  <>
                    I have at least 3 years of professional experience in at
                    least one of the target skills for this topic.
                  </>
                }
                name="experience"
                value={fields.experience}
                error={Object.keys(errors).includes("experience")}
                type="session"
              />
            </ErrorDisplay>

            <ErrorDisplay errors={errors} field="deadlines">
              <FormCheckbox
                onChange={handleCheckbox}
                labelText="I will show up at the agreed upon time for this session or give advance notice if I need to cancel."
                name="deadlines"
                value={fields.deadlines}
                error={Object.keys(errors).includes("deadlines")}
                type="project"
              />
            </ErrorDisplay>
          </FormSection>
        </div>
      </div>

      <div className="container">
        <div className="wrapper project-detail no-bottom">
          <FormSection>
            <PlainFormInfo
              full
              plain
              title={"Statement of Interest"}
              text="Please share a few lines about why your experience will make this session successful"
              required={true}
            />
            <ErrorDisplay errors={errors} field="interest">
              <Textarea
                name="interest"
                onChange={handleChange}
                error={Object.keys(errors).includes("interest")}
              />
            </ErrorDisplay>
          </FormSection>
        </div>
      </div>

      <div className="container">
        <div className="wrapper project-detail no-bottom">
          <FormSection>
            <PlainFormInfo full plain title="Your Availability" />
            <PlainFormInfo
              text="Select 5 or more times over at least 3 days for a phone call with the nonprofit contact."
              full
              plain
            />
            <PlainFormInfo
              text="* Timezone is adjusted automatically based on your IP address"
              full
              plain
            />
            <ErrorDisplay field="time_slots" errors={errors}>
              <DateSheetSelector
                name="time_slots"
                onChange={setTimeSlots}
                timeslots={[]}
                blockedDays={2}
                unavailableTimes={window.app.booked_times}
              />
            </ErrorDisplay>
          </FormSection>
        </div>
      </div>

      <div className="container apply_now">
        <div className="wrapper project-detail grey-card">
          <FormSection>
            <div className="l-row">
              <div className="l-col">
                <button
                  onClick={handleSubmit}
                  className="btn-trp is-active full"
                >
                  {" "}
                  Apply{" "}
                </button>
              </div>
              <div className="l-col"></div>
            </div>
          </FormSection>
        </div>
      </div>
    </div>
  );
};

if (document.querySelector("[data-js=calendar-scheduler]")) {
  ReactDOM.render(
    <ApplyNowScheduler />,
    document.querySelector("[data-js=calendar-scheduler]")
  );
}
