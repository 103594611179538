import React, { useState, useEffect } from "react";
import Moment from "moment";
import {
  Combobox,
  Checkmark,
  Unavailable,
  AddIcon,
  TimeIcon,
} from "./SvgIcons";

export const PlainFormInfo = ({
  title,
  text,
  big,
  plain,
  full,
  required,
  inline,
}) => {
  return (
    <div>
      {title && (
        <h2 className={`sub-title ${inline ? "inline" : ""}`}>{title}</h2>
      )}
      {text && (
        <div
          className={`info-trp ${full ? "full" : "plain"} ${
            required ? "input-label star" : ""
          }`}
        >
          <p> {text} </p>
        </div>
      )}
    </div>
  );
};

export const FormSectionTitle = ({ title, big }) => {
  return (
    <div className="l-page-title">
      <h1 className={`page-title ${big ? "" : "small"}`}>{title}</h1>
    </div>
  );
};

export const SkillWrapper = ({ name, value }) => {
  return (
    <div>
      <div className="skills-wrapper"></div>
      <input
        className="skills-input"
        type="hidden"
        name={name}
        value={value.join("|")}
      />
    </div>
  );
};

export const FormCheckbox = ({
  onChange,
  value,
  labelText,
  name,
  error = false,
  type = "",
}) => {
  return (
    <div className={`input-trp input-trp--check no-margin`}>
      <input
        type="checkbox"
        onChange={onChange(name)}
        className={`form-checkbox search ${error ? "error-input" : ""}`}
        name={name}
        id={name}
        checked={value}
      />
      <label
        htmlFor={name}
        className={`input-label checkbox ${error ? "error" : ""}`}
      >
        {labelText}
      </label>
    </div>
  );
};

export const FormSection = ({ children }) => {
  return (
    <div className="l-row form-section ">
      <div className="l-col">{children}</div>
    </div>
  );
};

export const ErrorDisplay = ({ errors, field, children }) => {
  return (
    <React.Fragment>
      {children}
      {errors[field] && (
        <div className="text-danger">
          {errors[field].map((text, ind) => (
            <span key={ind}>
              {text}
              <br />
            </span>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export const ProjectCategories = ({ onChange, defaultCat, required }) => {
  let [projectCats, setProjectCats] = useState([]);

  useEffect(() => {
    fetch("/api/app/nonprofits/sessions/project_categories")
      .then((response) => {
        return response.clone().json();
      })
      .then((cats) => {
        setProjectCats(cats.results);
      });
  }, [projectCats.length]);

  return (
    <div
      className={`input input-trp search ${required ? "required star" : ""}`}
    >
      <select
        value={defaultCat}
        className="form-select search padded "
        id="project-category-id"
        onChange={onChange}
      >
        <option value="">Select Category</option>
        {projectCats.map((cat) => {
          return (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const ProjectScoping = ({ value, onChange, required }) => {
  const [projectScoping] = useState([
    "Project scoping",
    "Problem-solving",
    "Brainstorming",
    "Training",
    "Other",
  ]);
  return (
    <div
      className={`input input-trp search ${required ? "required star" : ""}`}
    >
      <select
        value={value}
        className="form-select search padded"
        id="project-scope-id"
        onChange={onChange}
      >
        <option value="">Select Advice</option>
        {projectScoping.map((scope, index) => {
          return (
            <option key={index} value={scope}>
              {scope}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const SkillSet = ({ onChange, categoryId, value, required }) => {
  let [skillSet, setSkillSet] = useState([]);

  useEffect(() => {
    fetch(`/api/app/nonprofits/sessions/skill_sets?id=${categoryId}`)
      .then((response) => {
        return response.clone().json();
      })
      .then((skills) => {
        setSkillSet(skills.results);
      });
  }, [categoryId]);

  return (
    <div
      className={`input input-trp search ${required ? "required star" : ""}`}
    >
      <select
        value={value}
        className="form-select search padded"
        id="skill-id"
        onChange={onChange}
      >
        <option value="">Select Skill</option>
        {skillSet.map(function (skill) {
          return (
            <option key={skill.id} value={skill.name}>
              {skill.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export let LiTextfield = ({
  onChange,
  value,
  name,
  placeholder,
  error = false,
  labelText = "",
  dataCounter = "disabled",
  dataMinChars = null,
  dataMaxChars = null,
}) => {
  return (
    <div className="input input-trp search no-border-bottom">
      <input
        className={`input search padded ${
          error ? "error-input" : ""
        }`}
        type="text"
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        data-counter={dataCounter}
        data-min-chars={dataMinChars}
        data-max-chars={dataMaxChars}
      />
    </div>
  );
};

export let Textarea = ({
  onChange,
  value,
  name,
  placeholder,
  error = false,
  labelText = "",
  dataCounter = "disabled",
  dataMinChars = null,
  dataMaxChars = null,
}) => {
  return (
    <div className="input long_text input-trp search no-border-bottom">
      <textarea
        className={`input long_text form-textarea search padded ${
          error ? "error-input" : ""
        }`}
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        data-counter={dataCounter}
        data-min-chars={dataMinChars}
        data-max-chars={dataMaxChars}
      />
    </div>
  );
};

export const CalendarColumn = ({
  day,
  timeOffset,
  addTimeSlot,
  blockedDays,
}) => {
  const handleClick = (time) => {
    if (time.past) return;

    return (e) => {
      addTimeSlot(time);
    };
  };

  const buildClassNames = (time) => {
    let baseName = "time-slot";
    let isTimePast =
      typeof blockedDays === "number"
        ? time.time <
          Moment()
            .utcOffset(timeOffset / 60)
            .add(blockedDays, "days")
        : time.past;
    if (isTimePast || time.holiday || time.conflict) {
      baseName += " is-disabled";
    } else {
      if (time.schedulable)
        baseName += time.selected ? " is-selected" : " is-schedulable";
    }
    return baseName;
  };

  const setIcon = (time) => {
    let iconValue = <AddIcon />;

    if (time.past || time.holiday || time.conflict) iconValue = <Unavailable />;

    if (time.selected) iconValue = <TimeIcon />;

    return iconValue;
  };

  return (
    <div className="cal-column">
      <div className="title">
        <span className="day">{day[0].time.format("ddd")}</span>
        <span className="date">{day[0].time.format("MMM Do")}</span>
      </div>

      {day.map((time) => {
        return (
          <div
            key={time.time}
            className={buildClassNames(time)}
            onClick={handleClick(time)}
          >
            <span>{time.time.format("h:mm a")}</span>
          </div>
        );
      })}
    </div>
  );
};

export const PreviousIcon = ({ changeWeek }) => {
  return (
    <Combobox
      className="svg-icon rotate-left-90"
      onClick={changeWeek}
      title="Show Previous Week"
      position="left"
    />
  );
};

export const NextIcon = ({ changeWeek }) => {
  return (
    <Combobox
      className="svg-icon rotate-90"
      onClick={changeWeek}
      title="Show Next Week"
      position="right"
    />
  );
};

export const WeekChanger = ({ changeWeek, startDate, endDate, timeSlots }) => {
  const changeWk = (counter) => {
    return (e) => {
      changeWeek(counter);
    };
  };

  return (
    <div className="header">
      <PreviousIcon changeWeek={changeWk(-1)} />
      <div className="text">{timeSlots.length}/5 Selected</div>
      <NextIcon changeWeek={changeWk(1)} />
    </div>
  );
};

export const TimeSection = ({ basetime }) => {
  let timeAvailable = [];
  let time = basetime.clone();
  for (let i = 0; i <= 24; i++) {
    time = basetime.clone().add(i, "hour");
    let scheduleHour = time.hour();
    if (scheduleHour >= 9 && scheduleHour < 18) {
      timeAvailable.push(
        <div key={scheduleHour}>
          <div className="time-sideline">{time.format("h a")}</div>
          <div className="time-sideline"></div>
        </div>
      );
    }
  }
  return (
    <div className="time-column">
      <div className="cal-column">
        <div className="title">
          <span className="day"></span>
          <span className="date"></span>
        </div>
      </div>
      {timeAvailable}
    </div>
  );
};
